.Foot {
    display: flex;
    flex-direction: column;
    width: 70vw;
    padding: 3vh 15vw 3vh 15vw;
    background-color: #F2F2F2;
    color: #404040;
}

.Foot-component1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
    font-size: 0.8rem;
}

.Foot-component1 p {
    margin: 0;
}

.Foot-mainLogo {
    height: 2vh;
    margin-bottom: 0.3vh;
}

.Foot-component2 {
    width: 100%;
    padding: 3vh 0 3vh 0;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040;
}

.Foot-subComponent {
    width: 20%;
    font-size: 0.8rem;
    color: #404040;
}

.Foot-subComponent b {
    margin: 10px 0;
}

.Foot-subComponent p {
    margin: 10px 0;
}

.Foot-component3 {
    width: 100%;
    padding: 2vh 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
}

.Foot-component3 a {
    color: #404040;
    text-decoration: none;
    width: 30%;
}

.Foot-terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    width: 30%;
    text-align: right;
    white-space: nowrap;
}

.Foot-terms p {
    margin: 0 0.5vw 0 1vw;
}

.Foot-terms a:hover {
    text-decoration: underline;
    cursor: pointer;
}